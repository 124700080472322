<template>
  <div class="nav-header">
    <p>eAcademy Support</p>
    <h1 @click="drawer = true">Issues Tickets</h1>

    <div class="body-header">
      <div class="body-categories">
        <div class="categories-content">
          <i class="fas fa-chart-bar icon"></i>
          <h1>By Status</h1>
        </div>

        <div class="categories-content">
          <i class="fas fa-chart-bar"></i>
          <h1>By Assignee</h1>
        </div>

        <div class="categories-content">
          <i class="fas fa-chart-bar"></i>
          <h1>My Task</h1>
        </div>

        <div class="categories-content">
          <i class="fas fa-chart-bar"></i>
          <h1>Due Tasks</h1>
        </div>
      </div>

      <div class="filter">
        <div class="filter-content">
          <h5>Filter</h5>
          <h1>No Filter</h1>
        </div>

        <div class="alphabet">
          <h5>Sort</h5>
          <h1>A-Z</h1>
          <i class="fa fa-search"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
	data() {
		return {
			menuBars: [],
		};
	},
};
</script>


